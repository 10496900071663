import clsx from 'clsx'
import React from 'react'
import './styles.css'

type AlertVariant = 'info' | 'success' | 'warning' | 'danger'

interface AlertProps {
  className?: string
  variant?: AlertVariant
}

const Alert: React.FC<AlertProps> = ({
  className,
  variant = 'info',
  children,
}) => (
  <div
    className={clsx(
      'alert__container',
      `alert__container-${variant}`,
      className,
    )}
  >
    {children}
  </div>
)

export default Alert
