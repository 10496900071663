import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMaterialsData } from '../../lib/materials_hook'
import {
  getMaterialIidFromVariant,
  getProductMaterialFromIid,
} from '../../utils/MaterialUtils'
import { getMotiveIdFromSku } from '../../utils/MotiveUtils'
import {
  mapInternalKey2Slug,
  mapInternalProductMaterial2InternalKey,
  mapSlug2InternalKey,
  parseHashUrl,
} from '../../utils/UrlUtils'
import AlternativesOverview from '../../components/products/AlternativesOverview'
import ProductsOverview from '../../components/products/ProductsOverview'
import { withUrlSlugs } from '../../components/generic/withUrlSlugs'
import UpsellingAlert from './UpsellingAlert'
import './styles.css'
import { isBrowser } from '../../utils/utils'

interface UpsellingProps {
  location: any
  sku: string
  materialSlug: string
}

const UpsellingPage: FC<UpsellingProps> = ({ location, sku, materialSlug }) => {
  const materialsData = useMaterialsData()
  const { t } = useTranslation('translation')

  const motiveId = getMotiveIdFromSku(sku)
  const { variant } = parseHashUrl(location.hash)
  const materialIId = getMaterialIidFromVariant(variant)
  const productMaterial = getProductMaterialFromIid(materialsData, materialIId)
  const material = mapSlug2InternalKey(materialSlug)
  const materialParent = mapInternalKey2Slug(
    mapInternalProductMaterial2InternalKey(material),
  )
  const all = mapInternalKey2Slug('all')

  const robots = 'noindex, nofollow'

  // AlternativesOverview does not work for vouchers
  const showBestseller = productMaterial?.materialgroup === 'giftvoucher'

  if (isBrowser()) {
    window.scrollTo(...(window.lastScrollPosition || [0, 0]))
  }

  return (
    <>
      <Helmet>
        <meta content={robots} name="robots" />
      </Helmet>
        <div className="container">
          <h1>{t('Prima')}</h1>
          <UpsellingAlert
            bestsellerLink={`/${materialParent}/${materialSlug}/${all}/bestseller`}
            t={t}
          />
          <h2>{t('Das könnte dir auch gefallen')}</h2>
          {showBestseller ? (
            <ProductsOverview
              material="poster"
              productCount={10}
              sortingOrder="bestseller"
            />
          ) : (
            <AlternativesOverview
              key={'upselling-' + material + '-' + motiveId}
              label={'upselling'}
              material={material}
              motiveId={motiveId}
            />
          )}
        </div>
    </>
  )
}

export default withUrlSlugs(UpsellingPage)
