const { env } = require('../environment')
const { mapMaterialToApiGroup } = require('../src/utils/Mapping')
const fetch = require('node-fetch')

const fetchProductData = options => {
  let limit = 60
  let offset = 0

  let page = options.page
  if (typeof options.page !== 'undefined' && options.page < 1) {
    page = 1
  }

  if (typeof options.id !== 'undefined') {
    limit = 1
    offset = 0
  } else if (typeof page !== 'undefined') {
    offset = limit * (page - 1)
  }

  let material = mapMaterialToApiGroup(options.material)

  let url =
    env.getServiceHost() +
    'products/products?type=' +
    material +
    '&version=6' +
    '&limit=' +
    limit +
    '&offset=' +
    offset

  options.categories?.forEach(category => {
    if (category !== 'customizable') {
      url = url + '&categoryName[]=' + category
    } else {
      url = url + '&customizable=1'
    }
  })

  options.colors?.forEach(color => {
    url = url + '&color[]=' + color
  })
  options.ratios?.forEach(ratio => {
    url = url + '&ratio[]=' + ratio
  })
  options.artists?.forEach(artist => {
    url = url + '&artistName[]=' + artist
  })
  options.styles?.forEach(style => {
    url = url + '&style[]=' + style
  })

  if (options.ratio) {
    url = url + '&ratio=' + options.ratio
  }

  if (options.id) {
    if (typeof options.id === 'object') {
      url = url + '&id=' + options.id[0]
    } else {
      url = url + '&id=' + options.id
    }
  }

  if (options.searchQuery) {
    url = url + '&search=' + options.searchQuery
  }

  if (options.count) {
    url = url + '&count=1'
  }

  if (typeof options.bundleId !== 'undefined' && options.bundleId > 0) {
    url = url + '&bundleId=' + options.bundleId
  }

  if (
    [
      'wallcalendar',
      'foldingcalendar',
      'deskcalendar',
      'squarecalendar',
      'familyplaner',
    ].indexOf(material) !== -1 &&
    env.getCmsLanguage() === 'en'
  ) {
    url = url + '&country=uk'
  } else if (env.getLanguage() !== 'de') {
    url = url + '&language=' + env.getLanguage()
  }

  if (material === 'giftvoucher') {
    url = url + '&sortingOrder=new-in'
  } else {
    if (typeof options.sortingOrder !== 'undefined') {
      if (options.sortingOrder === 'bestseller') {
        url = url + '&sortingOrder=ranking'
      } else if (options.sortingOrder === 'bestseller-variant') {
        url = url + '&sortingOrder=ranking' // ranking2
      } else {
        url = url + '&sortingOrder=' + options.sortingOrder
      }
    }
  }

  return fetch(url).then(response => response.json())
}

module.exports = { fetchProductData }
